<template>
  <div class="" style="margin-left: 20px">
    <apexchart
      type="bar"
      height="250"
      width="100%"
      :options="chartOptionsGetter"
      :series="seriesGetter"
    ></apexchart>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

function parseFloatFromSigmaString(sigmaString) {
  return parseFloat(sigmaString.replaceAll(",", ""));
}

export default {
  name: "EngagementCharts",
  data() {
    return {
      ready: false,
    };
  },
  mounted() {
    let _this = this;
    setTimeout(() => {
      _this.ready = true;
    }, 150);
  },
  computed: {
    ...mapGetters(["engagementActivitySlices"]),
    chartOptionsGetter() {
      return {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: true,
          },
        },
        // Engaging and bright modern green palette
        colors: ["#c7e9c0", "#a8dda8", "#8ac991", "#69b565", "#4a9f3d"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 1,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        xaxis: {
          categories: this.engagementActivitySlices.map((a) => a.activityName),
        },
        legend: {
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
      };
    },
    seriesGetter() {
      return [
        {
          name: "Costos AutoF USD/ha",
          data: this.engagementActivitySlices
            .map((s) => s.totalAutofinanciableCostsByHectare)
            .map((s) => parseFloatFromSigmaString(s))
            .map((i) => Math.round(i, 0)),
        },
        {
          name: "Costos NoAutoF USD/ha",
          data: this.engagementActivitySlices
            .map((s) => s.totalNonautofinanciableCostsByHectare)
            .map((s) => parseFloatFromSigmaString(s))
            .map((i) => Math.round(i, 0)),
        },
      ];
    },
  },
};
</script>

<style></style>
