<template>
  <v-container fluid class="px-4">
    <v-row v-if="!isPageLoading">
      <v-col cols="12">
        <div>
          <div>
            <v-btn
              x-small
              class="white--text mt-2 ml-2"
              color="warning"
              elevation="0"
              @click="
                () =>
                  this.$router.push({
                    path: `/abstract-campaign/${singleEngagementSummary.abstractCampaignId}/campaign/${singleEngagementSummary.campaignId}`,
                  })
              "
            >
              <v-icon small> mdi-arrow-left </v-icon>
            </v-btn>
          </div>
          <div style="width: 100%" class="ml-2">
            <h2 class="overline mt-4 grey--text d-flex">
              {{ singleEngagementSummary.campaignName }} - Versión
              {{ singleEngagementSummary.campaignVersion }}
            </h2>
            <span v-if="hasRootDraft" class="overline"
              >ORIGEN:
              <router-link
                :to="{
                  name: 'SingleDraftEngagement',
                  params: { id: rootDraftId },
                }"
              >
                {{ rootDraftName }}
              </router-link>
            </span>
            <h2>{{ singleEstablishment.name }}</h2>
            <h3 class="" style="font-weight: 90">
              <span>
                <strong>
                  {{ singleEngagement.physicalHectares }}
                </strong>
                ha. físicas
              </span>
              <span class="ms-2">
                <strong>
                  {{ sowHectares }}
                </strong>
                ha. sembradas
              </span>

              <span class="ms-2">
                <strong>
                  {{ hectaresRatio }}
                </strong>
                fisicas / sembradas
              </span>
            </h3>
            <div
              v-if="singleEngagement.contractType === null"
              class="d-flex mt-2 ml-4"
            >
              <v-alert type="warning"> No hay un contrato establecido </v-alert>
            </div>
            <div
              v-else-if="
                singleEngagement.contractType != fixedUsdContractType &&
                checkRentPaymentPlanCompliance !== 0
              "
              class="d-flex mt-2 ml-4"
            >
              <v-alert type="warning"> Revisar Alquileres </v-alert>
            </div>
            <div class="d-flex mt-2">
              <!-- <v-btn
                x-small
                class="white--text ml-2"
                color="secondary"
                elevation="0"
                @click="() => this.$router.push({ path: '/engagement' })"
                >All Engagements</v-btn
              > -->
              <v-btn
                x-small
                class="white--text ml-2"
                elevation="0"
                color="blue"
                @click="
                  () =>
                    this.$router.push({
                      path: '/establishment/' + this.singleEstablishment.id,
                    })
                "
                >Establecimiento</v-btn
              >
            </div>
          </div>

          <div class="d-flex mt-2">
            <v-btn
              text
              elevation="0"
              :color="showActivityCards ? 'primary' : 'grey'"
              @click="showEngagementSection('showActivityCards')"
            >
              Cultivos
            </v-btn>

            <v-btn
              text
              class="ml-2"
              elevation="0"
              :color="showMetrics ? 'primary' : 'grey'"
              @click="showEngagementSection('showMetrics')"
            >
              Metricas
            </v-btn>

            <v-btn
              text
              class="ml-2"
              elevation="0"
              :color="showNewAS ? 'primary' : 'grey'"
              @click="showEngagementSection('showNewAS')"
            >
              Nueva Actividad
            </v-btn>
            <v-btn
              text
              class="ml-2"
              elevation="0"
              :color="showRents ? 'primary' : 'grey'"
              @click="showEngagementSection('showRents')"
            >
              Arrendamientos
            </v-btn>

            <v-btn
              text
              class="ml-2"
              elevation="0"
              :color="showCashflows ? 'primary' : 'grey'"
              @click="showEngagementSection('showCashflows')"
            >
              Cashflows
            </v-btn>
            <v-btn
              text
              class="ml-2"
              elevation="0"
              :color="showActions ? 'primary' : 'grey'"
              @click="showEngagementSection('showActions')"
            >
              Acciones
            </v-btn>
            <v-btn
              text
              class="ml-2"
              elevation="0"
              :color="showLineage ? 'primary' : 'grey'"
              @click="showEngagementSection('showLineage')"
            >
              Linaje
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="showActivityCards && !isActivitySlicesEmpty">
      <v-col cols="12">
        <EngagementCharts />
      </v-col>
      <v-col
        lg="4"
        sm="12"
        md="4"
        v-for="activitySlice in engagementActivitySlices"
        :key="activitySlice.activitySliceId"
      >
        <v-card
          elevation="0"
          color="grey lighten-3"
          @click="
            (e) =>
              $router.push(`/activity-slice/${activitySlice.activitySliceId}`)
          "
        >
          <v-card-text style="font-size: 22px">
            <div class="ml-2 black--text">
              {{ activitySlice.activityName }}
            </div>
            <div class="ml-2 black--text">
              ${{ activitySlice.totalRevenue }}
              <span class="grey--text"> Ingresos Brutos</span>
            </div>
            <div class="ml-2 black--text">
              {{ activitySlice.assignedHectares }} Ha.
              <span class="grey--text"> Asignadas</span>
            </div>
            <div class="ml-2 black--text">
              {{ usedHaPercent(activitySlice) }} %
              <span class="grey--text">Ha. Asignadas / Totales</span>
            </div>
            <div class="ml-2 black--text">
              {{ activitySlice.dynamicYield }} qq./Ha.
              <span class="grey--text">Rinde</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="showActivityCards && isActivitySlicesEmpty && !isPageLoading">
      <v-col class="d-flex justify-center pt-6 mt-10">
        <p class="button Button text--secondary text--lighten-5">
          Todavia no se encuentra cargada ninguna actividad a este Engagement
        </p>
      </v-col>
    </v-row>
    <v-row v-if="showNewAS">
      <v-col>
        <div class="ml-4 d-flex flex-column grey lighten-3 rounded">
          <div class="px-4 py-4">
            <v-select
              label="Cultivo"
              :items="availableNewActivities"
              item-text="name"
              item-value="activityId"
              dense
              type="number"
              class="managrx-input mr-2"
              v-model.number="newAS.activityId"
            ></v-select>

            <v-text-field
              label="Hectáreas asignadas"
              dense
              type="number"
              class="managrx-input mr-2"
              v-model.number="newAS.assignedHectares"
            ></v-text-field>
            <v-text-field
              label="Mes Siembra"
              dense
              type="date"
              class="managrx-input mr-2"
              v-model="newAS.sowDate"
              :min="campaignStartDate.split('T')[0]"
              :max="campaignEndDate.split('T')[0]"
              @blur="validateDate(newAS.sowDate, 'sowDate')"
            ></v-text-field>
            <v-text-field
              label="Mes Cosecha"
              dense
              type="date"
              class="managrx-input mr-2"
              v-model="newAS.harvestDate"
              :min="campaignStartDate.split('T')[0]"
              :max="campaignEndDate.split('T')[0]"
              @blur="validateDate(newAS.harvestDate, 'harvestDate')"
            ></v-text-field>
            <v-text-field
              label="Mes Ingreso"
              dense
              type="date"
              class="managrx-input mr-2"
              v-model="newAS.incomeDate"
              :min="campaignStartDate.split('T')[0]"
              :max="campaignEndDate.split('T')[0]"
              @blur="validateDate(newAS.incomeDate, 'incomeDate')"
              @change="setDefaultInsuranceMaterializationDate"
            ></v-text-field>
            <v-text-field
              label="Rinde esperado"
              dense
              type="number"
              class="managrx-input mr-2"
              v-model.number="newAS.forwardYield"
            ></v-text-field>
            <v-select
              label="Aparcería Fija"
              :items="[
                { name: 'Entrega KG', value: true },
                { name: 'No entrega KG', value: false },
              ]"
              item-text="name"
              item-value="value"
              dense
              type="number"
              class="managrx-input mr-2"
              v-model="newAS.isFixedSharecroppingHandedOver"
            ></v-select>
            <v-select
              label="Aparcería Variable"
              :items="[
                { name: 'Entrega KG', value: true },
                { name: 'No entrega KG', value: false },
              ]"
              item-text="name"
              item-value="value"
              dense
              type="number"
              class="managrx-input mr-2"
              v-model="newAS.isVariableSharecroppingHandedOver"
            ></v-select>
            <v-text-field
              label="Arrendamiento (0-100)%"
              dense
              type="number"
              class="managrx-input mr-2"
              v-model.number="newAS.rentPercentApplication"
            ></v-text-field>
            <v-select
              label="Seguros"
              :items="[
                'Semillas',
                'Herbicidas',
                'Insecticidas',
                'Fertilizantes',
                'Fungicidas',
                'Labranza',
                'Aplicaciones',
                'Seguimiento y Control',
                'Arrendamientos',
                'Varios',
              ]"
              clearable
              v-model="newAS.insuranceCategories"
              dense
              class="managrx-input mr-2"
              multiple
            >
            </v-select>
            <v-text-field
              label="Seguro (0-100)%"
              dense
              type="number"
              class="managrx-input mr-2"
              v-model.number="newAS.insuranceRate"
            ></v-text-field>
            <v-text-field
              label="Mes de materialización del seguro"
              dense
              type="date"
              class="managrx-input mr-2"
              :min="campaignStartDate.split('T')[0]"
              :max="campaignEndDate.split('T')[0]"
              @blur="
                validateDate(
                  newAS.insuranceMaterializationDate,
                  'insuranceMaterializationDate'
                )
              "
              v-model="newAS.insuranceMaterializationDate"
            ></v-text-field>
            <v-text-field
              label="Calidad Grano (0-100)%"
              dense
              type="number"
              class="managrx-input mr-2"
              v-model.number="newAS.grainQualityFactor"
            ></v-text-field>
            <v-btn
              color="blue white--text"
              @click="saveNewActivitySlice"
              depressed
            >
              <v-icon> mdi-floppy </v-icon></v-btn
            >
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="showRents">
      <v-col>
        <RentInput
          v-if="singleEngagement"
          :defaultDate="campaignStartDate"
          :singleEngagement="singleEngagement"
          :rentActivities="rentActivities"
          :engagementActivitySlices="engagementActivitySlices"
          :physicalHectares="singleEngagement.physicalHectares"
          @refreshInput="refreshInput"
        />
      </v-col>
    </v-row>

    <v-row v-if="showMetrics && !isActivitySlicesEmpty">
      <v-col lg="6" md="6" sm="12" v-if="showT1InfoGrid">
        <InfoGrid
          class="my-2"
          :infos="t1MetricsInfoGrid"
          infoTitle="Resultados Estimados Totales"
          :columns="5"
          infoTitleClass="grey--text h2"
          valueClass="display-1"
          :buttonTag="changeUnitsButtonTagT1"
          @buttonClick="setMetricUnits('t1')"
          :buttonShow="true"
          @buttonShowClick="showT1InfoGrid = !showT1InfoGrid"
        />
      </v-col>
      <v-col lg="6" md="6" sm="12" v-if="!showT1InfoGrid">
        <AbstractDraftMetricDataTable
          :title="'Resultados Estimados Totales'"
          :hideFooter="true"
          :items="t1Metrics"
          :buttonTag="changeUnitsButtonTagT1"
          :headers="tHeaders.t1Headers"
          @buttonClick="setMetricUnits('t1')"
          :buttonShow="true"
          @buttonShowClick="showT1InfoGrid = !showT1InfoGrid"
        />
      </v-col>
      <v-col lg="6" md="6" sm="12" v-if="showT2InfoGrid">
        <InfoGrid
          class="my-2"
          :infos="t2Values"
          :subTitles="t2SubTitles"
          infoTitle="Rinde y Precio Por Cultivo"
          infoTitleClass="grey--text h2"
          valueClass="display-1"
          :buttonShow="true"
          @buttonShowClick="showT2InfoGrid = !showT2InfoGrid"
        />
      </v-col>
      <v-col lg="6" md="12" sm="12" v-if="!showT2InfoGrid">
        <AbstractDraftMetricDataTable
          :title="'Rinde y Precio Por Cultivo'"
          :hideFooter="true"
          :items="t2Metrics"
          :headers="tHeaders.t2Headers"
          :groupBy="'activityName'"
          :orderBy="'activityName'"
          :hasSearch="true"
          :buttonShow="true"
          @buttonShowClick="showT2InfoGrid = !showT2InfoGrid"
        />
      </v-col>
    </v-row>

    <v-row v-if="showMetrics && !isActivitySlicesEmpty">
      <v-col lg="12" md="12" sm="12" v-if="showT3InfoGrid">
        <InfoGrid
          class="my-2"
          :infos="t3MetricsInfoGrid"
          :subTitles="t3SubTitles"
          infoTitle="Rentabilidad Por Cultivo"
          infoTitleClass="grey--text h2"
          valueClass="display-1"
          :buttonTag="changeUnitsButtonTagT3"
          @buttonClick="setMetricUnits('t3')"
          :buttonShow="true"
          @buttonShowClick="showT3InfoGrid = !showT3InfoGrid"
        />
      </v-col>
      <v-col lg="12" md="12" sm="12" v-if="!showT3InfoGrid">
        <AbstractDraftMetricDataTable
          :title="'Rentabilidad Por Cultivo'"
          :buttonTag="changeUnitsButtonTagT3"
          :hideFooter="true"
          :items="t3Metrics"
          :headers="tHeaders.t3Headers"
          :headersWidth="124"
          @buttonClick="setMetricUnits('t3')"
          :buttonShow="true"
          @buttonShowClick="showT3InfoGrid = !showT3InfoGrid"
        />
      </v-col>

      <v-col>
        <AbstractDraftMetricDataTable
          :title="'Breakdown de Costos'"
          :hideFooter="true"
          :items="t4Metrics"
          :headers="tHeaders.t4Headers"
        />
      </v-col>
      <v-col>
        <AbstractDraftMetricDataTable
          :title="'Aparcerias Por Cultivo'"
          :hideFooter="true"
          :items="t6Metrics"
          :headers="tHeaders.t6Headers"
        />

        <AbstractDraftMetricDataTable
          class="mt-2"
          :title="'Porcentajes Realizados Por Cultivo'"
          :hideFooter="true"
          :items="t8Metrics"
          :headers="tHeaders.t8Headers"
        />
      </v-col>
    </v-row>

    <v-row v-if="showMetrics && !isActivitySlicesEmpty">
      <v-col lg="6" md="12" sm="12">
        <AbstractDraftMetricDataTable
          :title="'Resumen Arrendamientos'"
          :hideFooter="true"
          :items="t5Metrics"
          :headers="tHeaders.t5Headers"
        />
      </v-col>
      <v-col lg="6" md="12" sm="12">
        <AbstractDraftMetricDataTable
          :title="'Resumen Arrendamientos qq & USD'"
          :hideFooter="true"
          :items="t7Metrics"
          :headers="tHeaders.t7Headers"
        />
      </v-col>
    </v-row>
    <v-row v-if="showMetrics && isActivitySlicesEmpty && !isPageLoading">
      <v-col class="d-flex justify-center pt-6 mt-10">
        <p class="button Button text--secondary text--lighten-5">
          Todavia no se encuentra cargada ninguna actividad a este Engagement
        </p>
      </v-col>
    </v-row>
    <v-row v-if="showCashflows && !isActivitySlicesEmpty">
      <v-col>
        <DenseCashflowTable
          :rows="engagementCashflowRows"
          :columns="engagementCashflowColumns"
          :totalHaToolTip="engagementTotalHaToolTip"
          :isResourceUtilization="false"
          :startDate="campaignStartDate"
          :endDate="campaignEndDate"
        />
      </v-col>
      <v-col cols="12">
        <v-divider class="mt-2 mb-2"></v-divider>
        <h2 class="ml-4">Utilización de Recursos</h2>

        <v-select
          v-model="resourceUtilizationFilter"
          :items="resourceUtilizationFilterItems"
          label="Filtrar por estado"
          outlined
          dense
          class="mx-4 mt-2"
          style="max-width: 300px"
          @change="handleResourceUtilizationFilterChange"
        ></v-select>

        <div
          v-if="engagementResourceUtilizationColumns.length == 0"
          class="d-flex flex-row justify-center"
        >
          <span class="grey--text text-overline pa-5 ma-5">
            Sin recursos para mostrar todavía.
          </span>
        </div>
        <DenseCashflowTable
          name="Tabla de insumos"
          :rows="engagementResourceUtilizationRows"
          :columns="engagementResourceUtilizationColumns"
          :isResourceUtilization="true"
          :startDate="campaignStartDate"
          :endDate="campaignEndDate"
        />
      </v-col>
    </v-row>
    <v-row v-if="showCashflows && isActivitySlicesEmpty && !isPageLoading">
      <v-col class="d-flex justify-center pt-6 mt-10">
        <p class="button Button text--secondary text--lighten-5">
          Todavia no se encuentra cargada ninguna actividad a este Engagement
        </p>
      </v-col>
    </v-row>
    <v-row v-if="showActions">
      <v-col>
        <Actions @deleteSingleEngagement="deleteSingleEngagement" />
      </v-col>
    </v-row>
    <v-row v-if="showLineage">
      <v-col>
        <v-card elevation="0">
          <v-card-text>
            <div class="lineage-tree">
              <div
                v-for="(row, rowIndex) in lineageRows"
                :key="rowIndex"
                class="lineage-branch mb-8"
              >
                <div class="branch-header mb-2">
                  <v-chip
                    small
                    :color="
                      row.engagement_id === parseInt($route.params.id)
                        ? 'primary'
                        : 'grey'
                    "
                    text-color="white"
                    class="mr-2"
                  >
                    Ascendencia
                  </v-chip>
                  <span class="grey--text text--darken-1">
                    {{ row.lineage.length }} elementos
                  </span>
                </div>

                <div class="filler-40px"></div>

                <div class="d-flex align-center">
                  <div
                    v-for="(item, index) in row.lineage"
                    :key="index"
                    class="d-flex align-center"
                  >
                    <!-- Item card -->
                    <v-card
                      :color="getItemBackgroundColor(item, row.engagement_id)"
                      dark
                      class="lineage-item px-3 py-2"
                      elevation="2"
                    >
                      <div class="d-flex align-center">
                        <v-icon small class="mr-2">
                          {{
                            item.type === "engagement"
                              ? "mdi-check-circle"
                              : "mdi-pencil-circle"
                          }}
                        </v-icon>
                        <span class="font-weight-medium">
                          {{ getItemLabel(item) }}
                        </span>
                        <v-btn
                          x-small
                          text
                          class="ml-2"
                          @click="navigateToItem(item)"
                        >
                          Ver
                        </v-btn>
                      </div>
                    </v-card>

                    <!-- Arrow between items -->
                    <v-icon
                      v-if="index < row.lineage.length - 1"
                      color="grey"
                      class="mx-2"
                    >
                      mdi-arrow-right
                    </v-icon>
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped>
/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: orange;
}

*::-webkit-scrollbar-thumb {
  background-color: blue;
  border-radius: 20px;
  border: 3px solid orange;
}

.nav-item {
  margin-left: 100px;
}

.single-activity-card:hover {
  border-width: 1px;
  border-style: solid;
  border-color: rgb(63, 94, 251);
  background: rgba(33, 150, 243, 1);
}

.single-activity-card:hover > * {
  color: white;
}

.single-activity-card {
  border-width: 1px;
  border-style: solid;
  border-color: rgb(41, 175, 0);
}

.single-activity-card:hover > .text-subtitle-1 {
  color: white;
}

.lineage-tree {
  max-width: 100%;
  overflow-x: auto;
}

.lineage-branch {
  position: relative;
}

.lineage-item {
  min-width: 120px;
  border-radius: 4px;
}

.branch-header {
  display: flex;
  align-items: center;
}

.filler-40px {
  margin-bottom: 40px;
  margin-top: 40px;
}
</style>
<script>
import { mapGetters } from "vuex";
import { postRequest, putRequest, deleteRequest } from "@/managrx/serverCall";
import DenseCashflowTable from "../components/WIP/DenseCashflowTable.vue";
import RentInput from "../components/Project/RentInput.vue";
import { unPackAndProcessCashflowKeys } from "../utils";
import EngagementCharts from "../components/Project/EngagmentCharts.vue";
import AbstractDraftMetricDataTable from "../components/Abstract/AbstractDraftMetricDataTable.vue";
import InfoGrid from "../components/Project/InfoGrid.vue";
import { roundToX } from "../managrx/utils";
import Actions from "../components/Project/Actions.vue";
import {
  engagementLevelTotalHaToolTip,
  FIXED_USD_CONTRACT,
  RENT_ACTIVITY_NAME_MAIZ,
  RENT_ACTIVTY_NAME_SOJA,
} from "../managrx/constants.js";
import { makeCampaignInfoGrid } from "../managrx/services";

function toNumberWithoutCommas(value) {
  if (typeof value === "string") {
    return Number(value.replace(/,/g, ""));
  }
  return value;
}

async function dispatchMounted(_this) {
  _this.$store.dispatch("clearActivitySlices");
  _this.$store.dispatch("clearSingleActivitySlice");
  _this.$store.dispatch("getSingleEngagement", _this.$route.params.id);
  await Promise.all([
    _this.$store.dispatch("getSingleEngagementSummary", _this.$route.params.id),
    _this.$store.dispatch("getEngagementActivitySlices", {
      filterKey: "engagement_id",
      filterValue: _this.$route.params.id,
    }),
  ]);
  _this.$store.dispatch(
    "getActivityPricesFromCampaign",
    _this?.singleEngagementSummary?.campaignId
  );

  if (_this.isAttachedEngagement) {
    _this.$store.dispatch(
      "getParentDraftEngagement",
      _this.singleEngagement?.parentId
    );
  }
}

export default {
  components: {
    DenseCashflowTable,
    RentInput,
    EngagementCharts,
    AbstractDraftMetricDataTable,
    InfoGrid,
    Actions,
  },
  computed: {
    ...mapGetters([
      "engagementActivitySlices",
      "singleDraftEngagement",
      "activityPricesFromCampaign",
      "singleEngagement",
      "aggEngagementT3Metrics",
      "singleEstablishment",
      "singleEngagementSummary",
      "singleEngagementMetrics",
      "singleCampaign",
      "engagementCashflow",
      "isPageLoading",
      "engagementResourceUtilization",
      "singleEngagementLineage",
    ]),
    availableNewActivities() {
      let engActivityIds = this.engagementActivitySlices.map((el) =>
        parseInt(el.activityId)
      );
      return this.activityPricesFromCampaign.filter(
        (el) => !engActivityIds.includes(el.activityId)
      );
    },
    rentActivities() {
      return this.activityPricesFromCampaign.filter(
        (el) =>
          el?.name == RENT_ACTIVITY_NAME_MAIZ ||
          el?.name == RENT_ACTIVTY_NAME_SOJA
      );
    },
    sowHectares() {
      return this.engagementActivitySlices
        .map((slice) => toNumberWithoutCommas(slice.assignedHectares))
        .reduce((a, b) => a + b, 0);
    },
    hectaresRatio() {
      if (this.sowHectares && this.singleEngagement.physicalHectares) {
        return roundToX(
          this.singleEngagement.physicalHectares / this.sowHectares,
          2
        );
      }
      return 0;
    },
    parsedInsuranceCategories() {
      return this.newAS.insuranceCategories?.length
        ? this.newAS.insuranceCategories?.join(",")
        : null;
    },
    newASInsuranceRateAsDecimal() {
      return this.newAS.insuranceRate ? this.newAS.insuranceRate / 100 : null;
    },
    newASGrainQualityFactorAsDecimal() {
      return this.newAS.grainQualityFactor / 100;
    },
    parentNotNull() {
      return this.singleDraftEngagement?.draftName ? true : false;
    },
    isAttachedEngagement() {
      if (
        this.singleEngagement?.parentIdTablename &&
        this.singleEngagement?.parentIdTablename.includes("draft")
      ) {
        return true;
      } else {
        return false;
      }
    },
    newASFixedSharecroppingPercentAsDecimal() {
      // (0-100) to (0-1)
      return this.newAS.fixedSharecroppingPercent / 100;
    },
    newASRentPercentApplicationAsDecimal() {
      // (0-100) to (0-1)
      return this.newAS.rentPercentApplication / 100;
    },
    engagementTotalHaToolTip() {
      return engagementLevelTotalHaToolTip;
    },
    campaignStartDate() {
      return this.singleEngagementSummary?.campaignStartDate;
    },
    campaignEndDate() {
      return this.singleEngagementSummary?.campaignEndDate;
    },
    cashflowEndMonth() {
      let date = new Date(this.campaignEndDate);

      return date.getMonth() + 1;
    },
    cashflowStartMonth() {
      let date = new Date(this.campaignStartDate);

      return date.getMonth() + 1;
    },
    cashflowEndYear() {
      let date = new Date(this.campaignEndDate);

      return date.getFullYear();
    },
    cashflowStartYear() {
      let date = new Date(this.campaignStartDate);

      return date.getFullYear();
    },

    isActivitySlicesEmpty() {
      return this.engagementActivitySlices.length === 0;
    },
    checkRentPaymentPlanCompliance() {
      let totalPlanned = this.singleEngagement.rentPaymentPlan
        ?.map((pp) => pp.qqHa)
        ?.reduce((a, b) => a + b, 0);
      let delta = this.singleEngagement.rentQqHa;
      return delta - totalPlanned;
    },
    engagementCashflowColumns() {
      return unPackAndProcessCashflowKeys(this.engagementCashflow?.keys);
    },
    engagementCashflowRows() {
      return this.engagementCashflow?.rows;
    },
    engagementResourceUtilizationColumns() {
      return unPackAndProcessCashflowKeys(
        this.engagementResourceUtilization?.keys
      );
    },
    engagementResourceUtilizationRows() {
      return this.engagementResourceUtilization?.rows;
    },
    t1MetricsToLocalString() {
      let t1Metrics = this.singleEngagementMetrics?.t1;
      t1Metrics = t1Metrics.map((element) => {
        return {
          ...element,
          value: element.value.toLocaleString("en-US", {
            timeZone: "America/Argentina/Buenos_Aires",
          }),
          value_ha:
            element.unit != "%"
              ? element["value_ha"].toLocaleString("en-US", {
                  timeZone: "America/Argentina/Buenos_Aires",
                })
              : null,
        };
      });
      return t1Metrics;
    },
    t1Metrics() {
      let t1MetricsValues = this.t1MetricsToLocalString;
      if (!t1MetricsValues) {
        return [];
      }
      let t1Metrics;
      if (this.isUsdTotalMetricT1) {
        t1Metrics = t1MetricsValues;
      } else {
        t1Metrics = t1MetricsValues.map((element) => {
          return {
            value:
              element.unit == "%" ? element?.["value"] : element?.["value_ha"],
            unit:
              element.unit == "%" ? element?.["unit"] : element?.["unit_ha"],
            key: element?.["key"],
          };
        });
      }
      return t1Metrics;
    },
    t1MetricsInfoGrid() {
      let t1MetricsValues = this.t1MetricsToLocalString;
      if (!t1MetricsValues) {
        return [];
      }
      let t1Metrics;
      if (this.isUsdTotalMetricT1) {
        t1Metrics = t1MetricsValues;
      } else {
        t1Metrics = t1MetricsValues.map((element) => {
          return {
            value:
              element.unit == "%" ? element?.["value"] : element?.["value_ha"],
            unit:
              element.unit == "%" ? element?.["unit"] : element?.["unit_ha"],
            key: element?.["key"],
          };
        });
      }
      return makeCampaignInfoGrid(t1Metrics);
    },
    t2Values() {
      return this.engagementActivitySlices.map((el) => {
        return makeCampaignInfoGrid(
          [
            {
              unit: "usd/ha",
              value: el?.forwardPrice,
              key: "Precio",
            },
            {
              unit: "qq/ha",
              value: el?.dynamicYield,
              key: "Rinde",
            },
          ],
          0
        )[0];
      });
    },
    t2Metrics() {
      return this.engagementActivitySlices;
    },
    t2SubTitles() {
      return this.engagementActivitySlices.map((el) => el?.activityName);
    },
    t3Activities() {
      return [
        ...this.engagementActivitySlices,
        { activityName: "Total", ...this.aggEngagementT3Metrics?.[0] },
      ];
    },
    t3SubTitles() {
      return this.t3Activities.map((el) => el?.activityName);
    },
    t3MetricsInfoGrid() {
      let t3Metrics = this.t3Activities;

      return t3Metrics.map((el) => {
        return makeCampaignInfoGrid([
          {
            key: "Hectáreas Asignadas",
            value: el?.assignedHectares,
            unit: "ha",
          },
          {
            key: "Margen",
            value: !this.isUsdTotalMetricT3
              ? el?.profitsByHectare
              : el?.totalProfits,
            unit: this.metricUnitsT3,
          },
          {
            key: "Costo Total",
            value: !this.isUsdTotalMetricT3
              ? el?.totalCostsByHectare
              : el?.totalCosts,
            unit: this.metricUnitsT3,
          },
          {
            key: "Costo Directo",
            value: !this.isUsdTotalMetricT3
              ? el?.directCostsByHectare
              : el?.totalDirectCosts,
            unit: this.metricUnitsT3,
          },
          {
            key: "Autofinanciables",
            value: !this.isUsdTotalMetricT3
              ? el?.autofinanciableCostsByHectare
              : el?.totalAutofinanciableCosts,
            unit: this.metricUnitsT3,
          },
          {
            key: "No Autofinanciables",
            value: !this.isUsdTotalMetricT3
              ? el?.nonautofinanciableCostsByHectare
              : el?.totalNonautofinanciableCosts,
            unit: this.metricUnitsT3,
          },
          {
            key: "Iva Neto",
            value: !this.isUsdTotalMetricT3
              ? el?.ivaNetByHectares
              : el?.totalIvaNet,
            unit: this.metricUnitsT3,
          },
          {
            key: "Ingreso Neto/Gasto",
            value: el?.netIncomeOverExpensesPercent,
            unit: "%",
          },
        ])[0];
      });
    },
    t3Metrics() {
      let t3Metrics = this.t3Activities;
      return t3Metrics;
    },
    t4Metrics() {
      let t4Metrics = this.singleEngagementMetrics?.t4;
      t4Metrics.forEach((element) => {
        element.value = element.value.toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
        element.value_ha = element.value_ha.toLocaleString("en-US", {
          timeZone: "America/Argentina/Buenos_Aires",
        });
      });

      return t4Metrics;
    },
    t6Metrics() {
      return this.engagementActivitySlices;
    },
    t7Metrics() {
      if (this.singleEngagementMetrics?.t7.length == 0) {
        return [];
      }
      return this.singleEngagementMetrics?.t7.map((el) => {
        return {
          ...el,
          ["item_cost"]: el?.["item_cost"]
            ? el?.["item_cost"].toLocaleString("en-US", {
                timeZone: "America/Argentina/Buenos_Aires",
              })
            : 0,
        };
      });
    },
    t8Metrics() {
      if (this.singleEngagementMetrics?.t8.length == 0) {
        return [];
      }
      return this.singleEngagementMetrics?.t8;
    },
    t5Metrics() {
      return (
        this?.singleEngagementMetrics?.t5?.map((t5) => {
          return {
            ...t5,
            date: new Date(t5.date)
              .toLocaleString("es-AR", {
                timeZone: "America/Argentina/Buenos_Aires",
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
              .split(",")[0],
          };
        }) || []
      );
    },
    tHeaders() {
      return {
        t1Headers: [
          { text: "Indicator", value: "key", class: "secondary white--text" },
          { text: "Value", value: "value", class: "secondary white--text" },
          { text: "Unit", value: "unit", class: "secondary white--text" },
        ],
        t2Headers: [
          {
            text: "Actividad",
            value: "activityName",
            class: "secondary white--text",
          },
          {
            text: "Rinde Dinámico",
            value: "dynamicYield",
            class: "secondary white--text",
          },
          {
            text: "Precio",
            value: "forwardPrice",
            class: "secondary white--text",
          },
        ],
        t4Headers: [
          { text: "Metric", value: "key", class: "secondary white--text" },
          { text: "USD/Ha", value: "value_ha", class: "secondary white--text" },
          { text: "USD Total", value: "value", class: "secondary white--text" },
        ],
        t3Headers: [
          {
            text: "Activity",
            value: "activityName",
            class: "secondary white--text",
          },
          {
            text: "Hectáreas Asignadas",
            value: "assignedHectares",
            class: "secondary white--text",
          },
          {
            text: "Producción Total (tn)",
            value: "totalProducedTons",
            unit: "qq",
            class: "secondary white--text",
          },
          {
            text: "Margen " + this.metricUnitsT3,
            value: !this.isUsdTotalMetricT3
              ? "profitsByHectare"
              : "totalProfits",
            class: "secondary white--text",
          },
          {
            text: "Costo Total " + this.metricUnitsT3,
            value: !this.isUsdTotalMetricT3
              ? "totalCostsByHectare"
              : "totalCosts",
            class: "secondary white--text",
          },
          {
            text: "Costo Directo " + this.metricUnitsT3,
            value: !this.isUsdTotalMetricT3
              ? "directCostsByHectare"
              : "totalDirectCosts",
            class: "secondary white--text",
          },
          {
            text: "Autofinanciables " + this.metricUnitsT3,
            value: !this.isUsdTotalMetricT3
              ? "autofinanciableCostsByHectare"
              : "totalAutofinanciableCosts",
            class: "secondary white--text",
          },
          {
            text: "No Autofinanciables " + this.metricUnitsT3,
            value: !this.isUsdTotalMetricT3
              ? "nonautofinanciableCostsByHectare"
              : "totalNonautofinanciableCosts",
            class: "secondary white--text",
          },
          {
            text: "Iva Neto " + this.metricUnitsT3,
            value: !this.isUsdTotalMetricT3
              ? "ivaNetByHectares"
              : "totalIvaNet",
            class: "secondary white--text",
          },
          {
            text: "Ingreso Neto/Gasto %",
            value: "netIncomeOverExpensesPercent",
            class: "secondary white--text",
          },
        ],
        t5Headers: [
          { text: "Fecha", value: "date", class: "secondary white--text" },
          { text: "Cantidad", value: "qq_ha", class: "secondary white--text" },
          {
            text: "Precio",
            value: "rent_qq_activity_price",
            class: "secondary white--text",
          },
          {
            text: "Costo",
            value: "installment_cost",
            class: "secondary white--text",
          },
          {
            text: "Cuota",
            value: "installment_percent",
            class: "secondary white--text",
          },
        ],
        t6Headers: [
          {
            text: "Activity",
            value: "activityName",
            class: "secondary white--text",
          },
          {
            text: "Aparcerías (tn)",
            value: "tonsHandedOver",
            class: "secondary white--text",
          },
          {
            text: "Bonificacion Alquiler (tn)",
            value: "bonusTons",
            class: "secondary white--text",
          },

          {
            text: "Aparcería Variable",
            value: "variableSharecroppingCosts",
            class: "secondary white--text",
          },
          {
            text: "Aparcería Fija",
            value: "fixedSharecroppingCosts",
            class: "secondary white--text",
          },
          {
            text: "Aparcería USD/ha",
            value: "sharecroppingUsdHa",
            class: "secondary white--text",
          },
        ],
        t7Headers: [
          {
            text: "Actividad",
            value: "activity_name",
            class: "secondary white--text",
          },
          {
            text: "Arrendamiento USD",
            value: "item_cost",
            class: "secondary white--text",
          },
          {
            text: "Arrendamiento %",
            value: "item_cost_percent",
            class: "secondary white--text",
          },
          {
            text: "% App",
            value: "rent_percent_application",
            class: "secondary white--text",
          },
        ],
        t8Headers: [
          {
            text: "Actividad",
            value: "activity_name",
            class: "secondary white--text",
          },
          {
            text: "% Realizado",
            value: "actual_pct",
            class: "secondary white--text",
          },
          {
            text: "% No realizado",
            value: "scrapped_pct",
            class: "secondary white--text",
          },
          {
            text: "% Proyectado",
            value: "planned_pct",
            class: "secondary white--text",
          },
        ],
      };
    },
    lineageRows() {
      return this.singleEngagementLineage?.rows || [];
    },
    hasRootDraft() {
      const firstRow = this.lineageRows?.[0];
      if (!firstRow?.lineage?.length) return false;

      const rootNode = firstRow.lineage[0];
      return rootNode.type === "draft_engagement";
    },
    rootDraftId() {
      const firstRow = this.lineageRows?.[0];
      if (!firstRow?.lineage?.length) return null;

      const rootNode = firstRow.lineage[0];
      return rootNode.type === "draft_engagement" ? rootNode.id : null;
    },
    rootDraftName() {
      const firstRow = this.lineageRows?.[0];
      if (!firstRow?.lineage?.length) return null;

      const rootNode = firstRow.lineage[0];
      return rootNode.type === "draft_engagement" ? rootNode.name : null;
    },
  },
  beforeCreate() {
    this.$store.dispatch("clearSingleActivitySlice");
  },
  async mounted() {
    await dispatchMounted(this);
    document.title = `Engagement ` + this.$route.params.id;
  },
  methods: {
    setDefaultInsuranceMaterializationDate() {
      if (this.newAS.insuranceMaterializationDate === null) {
        this.newAS.insuranceMaterializationDate = this.newAS.incomeDate;
        this.validateDate(
          this.newAS.insuranceMaterializationDate,
          "insuranceMaterializationDate"
        );
      }
    },
    refreshInput() {
      dispatchMounted(this);
    },
    validateDate(date, key) {
      let selectedDate;
      const minDate = new Date(this.campaignStartDate);
      const maxDate = new Date(this.campaignEndDate);
      if (new Date(date)) {
        selectedDate = new Date(date);
        if (selectedDate < minDate) {
          this.newAS[key] = this.campaignStartDate.split("T")[0];
        } else if (selectedDate > maxDate) {
          this.newAS[key] = this.campaignEndDate.split("T")[0];
        }
      }
    },

    async deleteSingleEngagement() {
      let engagementId = this.$route.params.id;
      let response = await deleteRequest(
        `/module/delete/engagement/${engagementId}`
      );
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.exitRoute();
      }
    },
    exitRoute() {
      this.$router.push({
        path: `/abstract-campaign/${this.singleEngagementSummary.abstractCampaignId}/campaign/${this.singleEngagementSummary.campaignId}`,
      });
    },
    usedHaPercent(activitySlice) {
      return roundToX(
        (parseInt(activitySlice.assignedHectares) * 100) /
          this.singleEngagement.physicalHectares,
        2
      ).toLocaleString("en-US", { timeZone: "America/Argentina/Buenos_Aires" });
    },
    setMetricUnits(table) {
      if (table == "t1") {
        if (this.isUsdTotalMetricT1) {
          this.metricUnitsT1 = "usd/ha";
          this.changeUnitsButtonTagT1 = "usd";
          this.isUsdTotalMetricT1 = false;
        } else {
          this.metricUnitsT1 = "usd";
          this.changeUnitsButtonTagT1 = "usd/ha";
          this.isUsdTotalMetricT1 = true;
        }
      } else if (table == "t3") {
        if (this.isUsdTotalMetricT3) {
          this.metricUnitsT3 = "usd/ha";
          this.changeUnitsButtonTagT3 = "usd";
          this.isUsdTotalMetricT3 = false;
        } else {
          this.metricUnitsT3 = "usd";
          this.changeUnitsButtonTagT3 = "usd/ha";
          this.isUsdTotalMetricT3 = true;
        }
      }
    },
    async showEngagementSection(selectedSection) {
      this.$store.dispatch("setPageLoadingStatus", true);

      let sections = {
        showNewAS: false,
        showRents: false,
        showMetrics: false,
        showActivityCards: false,
        showCashflows: false,
        showActions: false,
        showLineage: false,
      };
      sections[selectedSection] = true;
      this.showNewAS = sections.showNewAS;
      this.showRents = sections.showRents;
      this.showMetrics = sections.showMetrics;
      this.showActivityCards = sections.showActivityCards;
      this.showCashflows = sections.showCashflows;
      this.showActions = sections.showActions;
      this.showLineage = sections.showLineage;
      this.$store.dispatch("clearEngagemetMetricsAndCashflow");

      // Load data when corresponding tab is clicked
      if (selectedSection === "showMetrics" && !this.isActivitySlicesEmpty) {
        await this.$store.dispatch("getSingleEngagementMetrics", {
          id: this.$route.params.id,
          startYear: this.cashflowStartYear,
          endYear: this.cashflowEndYear,
          startMonth: this.cashflowStartMonth,
          endMonth: this.cashflowEndMonth,
        });
      }

      if (selectedSection === "showCashflows" && !this.isActivitySlicesEmpty) {
        const sliceIds = this.engagementActivitySlices.map(
          (slice) => slice.activitySliceId
        );

        await Promise.all([
          this.$store.dispatch("getEngagementCashflow", {
            startYear: this.cashflowStartYear,
            endYear: this.cashflowEndYear,
            startMonth: this.cashflowStartMonth,
            endMonth: this.cashflowEndMonth,
            engagementId: this.$route.params.id,
            activitySliceIds: sliceIds,
          }),
          this.$store.dispatch("getEngagementResourceUtilization", {
            sliceIds,
          }),
        ]);
      }

      this.$store.dispatch("setPageLoadingStatus", false);
    },

    navigateToActivitySlice(activitySlice) {
      this.$router.push(`/activity-slice/${activitySlice.activitySliceId}`);
    },
    handleRowClick(e) {
      this.$router.push({
        path: `/activity-slice/${e.activitySliceId}`,
      });
    },
    async saveNewActivitySlice() {
      let payload = {
        engagementId: this.$route.params.id,
        ...this.newAS,
        sowDate: new Date(this.newAS.sowDate).toISOString(),
        harvestDate: new Date(this.newAS.harvestDate).toISOString(),
        incomeDate: new Date(this.newAS.incomeDate).toISOString(),
        fixedSharecroppingPercent: this.newASFixedSharecroppingPercentAsDecimal,
        rentPercentApplication: this.newASRentPercentApplicationAsDecimal,
        grainQualityFactor: this.newASGrainQualityFactorAsDecimal,
        insuranceRate: this.newASInsuranceRateAsDecimal,
        insuranceCategories: this.parsedInsuranceCategories,
        insuranceMaterializationDate: new Date(
          this.newAS.insuranceMaterializationDate
        ).toISOString(),
      };

      try {
        let response = await postRequest("/activity_slice", payload);
        if (!response.success) {
          this.$store.dispatch("setNewNotification", response);
        } else {
          // Check if a bonification row must be initialized
          if (
            this.singleEngagement.contractType === 0 &&
            this.singleEngagement.rentBonuses?.length > 0
          ) {
            let bonusResponse = await postRequest("/engagement_rent_bonus/", {
              engagementId: this.singleEngagement?.id,
              activityId: this.newAS?.activityId,
              rentBonusPercent: 0,
              yieldThreshold: 0,
              isUsdHa: this.singleEngagement?.rentBonuses?.[0]?.isUsdHa, // wip
              bonusDate: new Date(this.newAS.harvestDate).toISOString(),
            });
            if (!bonusResponse.success) {
              this.$store.dispatch("setNewNotification", response);
            }
          }
        }
      } catch (error) {
        let notification = {
          type: "error",
          text: `${error?.response?.data?.error}`,
        };
        this.$store.dispatch("setNewNotification", notification);
        console.error(error);
        console.error(error.response);
      }

      dispatchMounted(this);

      this.newAS = {
        activityId: null,
        insuranceCategories: ["Semillas"],
        insuranceRate: 1.5,
        insuranceMaterializationDate: null,
        incomeDate: null,
        harvestDate: null,
        sowDate: null,
        assignedHectares: 0,
        forwardYield: 0,
        isFixedSharecroppingHandedOver: true,
        isVariableSharecroppingHandedOver: true,
        minHarvestCost: 0,
        maxHarvestCost: 0,
        harvestPercentCost: 0.1,
        rentPercentApplication: 100,
        freightCostTn: 0,
        fixedSharecroppingPercent: 0,
        grainQualityFactor: 100,
        saleTax: 0.0075,
        saleComission: 0.005,
        saleHandOver: 0,
        technicalDirection: 0.025,
      };
      this.showEngagementSection("showActivityCards");
    },
    async putSingleEngagement(payload) {
      let eId = this.singleEngagement.id;
      let response = await putRequest(`/engagement/${eId}`, payload);
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        location.reload();
      }
    },

    async postPaymentPlanInstallment(payload) {
      payload["engagementId"] = this.singleEngagement.id;
      let response = await postRequest(
        `/engagement_rent_payment_plan`,
        payload
      );
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        dispatchMounted(this);
      }
    },
    async updatePaymentPlanInstallment(event) {
      let response = await putRequest(
        `/engagement_rent_payment_plan/${event.id}`,
        event.payload
      );
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        dispatchMounted(this);
      }
    },
    async deletePaymentPlanInstallment(installmentId) {
      let response = await deleteRequest(
        `/engagement_rent_payment_plan/${installmentId}`
      );
      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        dispatchMounted(this);
      }
    },
    handleResourceUtilizationFilterChange(value) {
      localStorage.setItem("actualFilter", value);
      this.getEngagementResourceUtilization();
    },

    async getEngagementResourceUtilization() {
      const sliceIds = this.engagementActivitySlices.map(
        (slice) => slice.activitySliceId
      );

      if (sliceIds.length > 0) {
        await this.$store.dispatch("getEngagementResourceUtilization", {
          sliceIds,
        });
      }
    },
    getItemBackgroundColor(item, rowEngagementId) {
      // Only color items if they are engagements
      if (item.type === "engagement") {
        if (parseInt(this.$route.params.id) === item.id) {
          return "primary"; // Current engagement being viewed
        }
        if (rowEngagementId === item.id) {
          return "deep-purple"; // End of each branch
        }
        return "blue-grey"; // Regular engagements
      }
      return "grey darken-2"; // All drafts
    },

    navigateToItem(item) {
      if (item.type === "engagement") {
        this.$router.push(`/engagement/${item.id}`);
      } else {
        this.$router.push({
          name: "SingleDraftEngagement",
          params: { id: item.id },
        });
      }
    },
    getItemLabel(item) {
      if (item.type === "draft_engagement" && item.name) {
        return item.name;
      }
      return `${item.type === "engagement" ? "E" : "B"}#${item.id}`;
    },
  },
  data() {
    return {
      showT1InfoGrid: false,
      showT2InfoGrid: false,
      showT3InfoGrid: false,
      changeUnitsButtonTagT1: "usd",
      isUsdTotalMetricT1: false,
      metricUnitsT1: "usd/ha",
      changeUnitsButtonTagT3: "usd",
      isUsdTotalMetricT3: false,
      metricUnitsT3: "usd/ha",
      showNewAS: false,
      showRents: false,
      showMetrics: false,
      showActivityCards: true,
      showCashflows: false,
      showActions: false,
      showLineage: false,
      fixedUsdContractType: FIXED_USD_CONTRACT.value,
      newAS: {
        activityId: null,
        insuranceMaterializationDate: null,
        insuranceCategories: ["Semillas"],
        insuranceRate: 1.5,
        incomeDate: null,
        harvestDate: null,
        sowDate: null,
        assignedHectares: 0,
        forwardYield: 0,
        isFixedSharecroppingHandedOver: true,
        isVariableSharecroppingHandedOver: true,
        minHarvestCost: 0,
        maxHarvestCost: 0,
        harvestPercentCost: 0.1,
        rentPercentApplication: 100,
        freightCostTn: 0,
        fixedSharecroppingPercent: 0,
        grainQualityFactor: 100,
        saleTax: 0.0075,
        saleComission: 0.005,
        saleHandOver: 0,
        technicalDirection: 0.025,
      },
      resourceUtilizationFilter: localStorage.getItem("actualFilter") || "all",
      resourceUtilizationFilterItems: [
        { text: "Todo", value: "all" },
        { text: "Realizado", value: "actuals" },
        { text: "No Realizado", value: "forecast" },
      ],
    };
  },
};
</script>
